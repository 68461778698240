import React, { useEffect, useState } from 'react'
import { DataTable } from '../../../components/data-table'
import { CustomersDataGridCols } from './customer-data-grid-cols';
import { DynamicForm } from '../../../components/form-builder/form';
import { useNavigate } from 'react-router-dom';
import { GET_USERS, USER_CREATE, USER_DELETE, USER_UPDATE } from '../../../app/services/users.service';
import CountryCodes from '../../../app/utils/mobile-number-country-code.json';
import toast from 'react-hot-toast';
import { Loader } from '../../../components/loader';
import { Search } from '../../../components/search/search';

export const Customers = () => {
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<any>([]);
    const navigation = useNavigate();

    const [pageCount, setPageCount] = useState<any>({
        limit: 10,
        page: 0,
        user_role: 'CUSTOMER'
    });

    const [formData, setFormData] = useState<any>([
        {
            title: "input",
            type: "text",
            label: "Company Name",
            name: "name",
            formFloating: false,
            value: '',
            regex: "",
            required: true,
            placeholder: "Please enter company name",
            error: "Please enter company name",
            hideMandatoryStar: false,
        },
        {
            title: "input",
            type: "text",
            label: "Email",
            name: "email",
            formFloating: false,
            value: '',
            regex: /^[a-zA-Z0-9._%+-]+@[a-z]{3,10}.[a-z]{2,5}$/,
            required: false,
            placeholder: "Please enter email",
            error: "Please enter email",
            hideMandatoryStar: true,
        },
        {
            title: "dropdown",
            type: "text",
            label: "code",
            name: "mobile_country_code",
            formFloating: true,
            value: '',
            option: CountryCodes,
            regex: "",
            required: true,
            placeholder: "Code",
            error: "Please select code",
            hideMandatoryStar: false,
            column: 2,
            paddingZero: true,
            customParentClass: "pe-0",
            customClass: "rounded-end-0"
        },
        {
            title: "input",
            type: "text",
            label: "Phone Number",
            formFloating: false,
            name: "mobile_number",
            value: '',
            column: 4,
            regex: /^(?:\(?\d{2,4}\)?[\s.-]?)?\d{3,4}[\s.-]?\d{3,4}[\s.-]?\d{0,4}$/,
            maxLength: "15",
            required: true,
            placeholder: "Please enter phone number",
            error: "Please enter phone number",
            hideMandatoryStar: false,
            customParentClass: "ps-0",
            customClass: "rounded-start-0"
        },
        {
            title: "input",
            type: "password",
            label: "Password",
            name: "password",
            value: "",
            required: true,
            regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/,
            maxLength: "",
            hideMandatoryStar: false,
            isDisabled: true,
            placeholder: "Please enter password",
            isCopy: true,
            isView: true,
            column: 5,
            error: "Please enter password",
        },
        {
            title: "textarea",
            type: "text",
            label: "Address",
            name: "address",
            formFloating: false,
            value: '',
            regex: "",
            required: false,
            placeholder: "Please enter address",
            error: "Please enter address",
            hideMandatoryStar: true,
        },
        {
            title: "file",
            type: "file",
            label: "Profile Image",
            name: "profile_img",
            value: "",
            required: false,
            regex: "",
            maxLength: "",
            isMultipleImages: false,
            placeholder: "Upload image",
            error: "Please upload image",
            hideMandatoryStar: true
        },
        {
            type: "button",
            class: "col-md-12 d-flex justify-content-end my-2",
            buttons: [
                {
                    title: 'Create',
                    action: 'add',
                    isCheckFormValidation: true,
                    class: 'btn btn-primary px-4 btn-sm f16 me-1',
                    isDisabled: true
                },
                {
                    title: 'Cancel',
                    action: 'cancel',
                    class: 'btn btn-outline-primary px-4 btn-sm f16'
                }
            ]
        },
    ])

    const [searchFormData, setSearchFormData] = useState([
        {
            title: "input",
            type: "text",
            label: "Search",
            name: "search",
            formFloating: false,
            value: '',
            regex: "",
            placeholder: "Please enter search",
            error: "Please enter search",
            hideMandatoryStar: true,
        },
        {
            title: "input",
            type: "text",
            label: "Email",
            name: "email",
            formFloating: false,
            value: '',
            regex: /^[a-zA-Z0-9._%+-]+@[a-z]{3,10}.[a-z]{2,5}$/,
            placeholder: "Please enter email",
            error: "Please enter email",
            hideMandatoryStar: true,
        },
        {
            title: "input",
            type: "text",
            label: "Phone Number",
            formFloating: false,
            name: "mobile_number",
            value: '',
            maxLength: 10,
            regex: /^(?:\(?\d{2,4}\)?[\s.-]?)?\d{3,4}[\s.-]?\d{3,4}[\s.-]?\d{0,4}$/,
            placeholder: "Please enter phone number",
            error: "Please enter phone number",
            hideMandatoryStar: true,
        },
        {
            type: "button",
            class: "col-md-3 d-flex justify-content-end my-2 mt-3 pt-1",
            buttons: [
                {
                    title: 'Search',
                    action: 'add',
                    isCheckFormValidation: true,
                    class: 'btn btn-primary px-4 btn-sm f16 me-1 height_30 mt-3'
                },
                {
                    title: 'Cancel',
                    action: 'cancel',
                    class: 'btn btn-outline-primary px-4 btn-sm f16 height_30 mt-3'
                }
            ]
        },
    ])
    const [loading, setLoading] = useState<boolean>(false);
    const [tableListData, setTableListData] = useState<any>([]);
    const [password, setPassword] = useState<any>();
    const [dialCode, setDialCode] = useState<any>();


    useEffect(() => {
        navigator.geolocation.getCurrentPosition(function (position) {
            var lat = position.coords.latitude;
            var lon = position.coords.longitude;

            // Use a geolocation API to get country code from lat/lon
            var geolocationAPI = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${lon}&localityLanguage=en`;

            fetch(geolocationAPI)
                .then((response) => response.json())
                .then((data) => {
                    var countryCode = data.countryCode;
                    const locationCountry = CountryCodes.find(
                        (country: any) => country?.code === countryCode
                    );
                    setDialCode(locationCountry?.dial_code);
                });
        });

        getCustomers(pageCount);
    }, [])

    const getCustomers = (data: any) => {
        setLoading(true);
        GET_USERS(data).then((res: any) => {
            if (res?.status === "fail") {
                setLoading(false);
            } else {
                if (res) {
                    setTableListData(res?.data);
                    setLoading(false);
                }
            }
        });
    }

    const onActions = (data: any) => {
        setSelectedData(data?.data)
        if (data?.type === "Edit") {
            setIsEdit(true);
            setShowCreatePopup(true);
            const formDataObj = formData;
            if (formDataObj[4].name === 'password') {
                formDataObj.splice(4, 1);
            }
            formDataObj?.forEach((element: any) => {
                if (element?.type === "button") {
                    for (let i = 0; i < element?.buttons?.length; i++) {
                        const ele = element?.buttons;
                        ele[0].title = "Update";
                        ele[0].action = "edit";
                        ele[0].isDisabled = false;
                    }
                }
                element.value = data?.data[element.name]
            });
            setFormData([...formDataObj]);
        } else if (data?.type === "Delete") {
            setShowDeletePopup(true);
        } else if (data?.type === "link") {
            navigation(`/dashboard/customers/${data?.data?.uuid}/sites`)
        }
    }
    const onSelectPage = (data: any) => {
        const pageData = pageCount;
        pageData.page = data;
        setPageCount(pageData);
        getCustomers(pageData);
    };

    const onPageChange = (data: any) => {
        const pageData = pageCount;
        pageData.limit = data;
        setPageCount(pageData);
        getCustomers(pageData);
    };

    const onShowCreateCustomerPopup = async () => {
        setShowCreatePopup(true);
        setIsEdit(false);

        const categoryFormData = [
            {
                title: "input",
                type: "text",
                label: "Company Name",
                name: "name",
                formFloating: false,
                value: '',
                regex: "",
                required: true,
                placeholder: "Please enter company name",
                error: "Please enter company name",
                hideMandatoryStar: false,
            },
            {
                title: "input",
                type: "text",
                label: "Email",
                name: "email",
                formFloating: false,
                value: '',
                regex: /^[a-zA-Z0-9._%+-]+@[a-z]{3,10}.[a-z]{2,5}$/,
                required: false,
                placeholder: "Please enter email",
                error: "Please enter email",
                hideMandatoryStar: true,
            },
            {
                title: "dropdown",
                type: "text",
                label: "code",
                name: "mobile_country_code",
                formFloating: true,
                value: '',
                option: CountryCodes,
                regex: "",
                required: true,
                placeholder: "Code",
                error: "Please select code",
                hideMandatoryStar: false,
                column: 2,
                paddingZero: true,
                customParentClass: "pe-0",
                customClass: "rounded-end-0"
            },
            {
                title: "input",
                type: "text",
                label: "Phone Number",
                formFloating: false,
                name: "mobile_number",
                value: '',
                column: 4,
                regex: /^(?:\(?\d{2,4}\)?[\s.-]?)?\d{3,4}[\s.-]?\d{3,4}[\s.-]?\d{0,4}$/,
                maxLength: "15",
                required: true,
                placeholder: "Please enter phone number",
                error: "Please enter phone number",
                hideMandatoryStar: false,
                customParentClass: "ps-0",
                customClass: "rounded-start-0"
            },
            {
                title: "input",
                type: "password",
                label: "Password",
                name: "password",
                value: "",
                required: true,
                regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/,
                maxLength: "",
                hideMandatoryStar: false,
                isDisabled: true,
                placeholder: "Please enter password",
                isCopy: true,
                isView: true,
                column: 5,
                error: "Please enter password",
            },
            {
                title: "textarea",
                type: "text",
                label: "Address",
                name: "address",
                formFloating: false,
                value: '',
                regex: "",
                required: false,
                placeholder: "Please enter address",
                error: "Please enter address",
                hideMandatoryStar: true,
            },
            {
                title: "file",
                type: "file",
                label: "Profile Image",
                name: "profile_img",
                value: "",
                required: false,
                regex: "",
                maxLength: "",
                isMultipleImages: false,
                placeholder: "Upload image",
                error: "Please upload image",
                hideMandatoryStar: true
            },
            {
                type: "button",
                class: "col-md-12 d-flex justify-content-end my-2",
                buttons: [
                    {
                        title: 'Create',
                        action: 'add',
                        isCheckFormValidation: true,
                        class: 'btn btn-primary px-4 btn-sm f16 me-1',
                        isDisabled: true
                    },
                    {
                        title: 'Cancel',
                        action: 'cancel',
                        class: 'btn btn-outline-primary px-4 btn-sm f16'
                    }
                ]
            },
        ];
        categoryFormData?.forEach((element: any) => {
            if (element?.type === "button") {
                for (let i = 0; i < element?.buttons?.length; i++) {
                    const ele = element?.buttons;
                    ele[0].title = "Create";
                    ele[0].action = "add";
                }
            }
            if (element?.name === "password") {
                element.value = generatePassword();
            } else if (element?.name === "mobile_country_code") {
                element.value = dialCode;
            } else {
                element.value = "";
            }
        })
        setFormData([...categoryFormData])
    }

    const onCustomerFormSubmitEvent = (event: any) => {
        const data = event;
        if (data?.type === "add") {
            const formData = event?.formData;
            formData.user_role = "CUSTOMER";
            formData.dob = formData.dob ? formData.dob : null;
            formData.metadata = {};
            delete formData['undefined'];
            setLoading(true);
            USER_CREATE(formData).then((res: any) => {
                if (res?.status === "fail") {
                    setLoading(false);
                    toast.error(res.error, { position: "top-right" });
                } else {
                    if (res) {
                        toast.success(res.message, { position: 'top-right' })
                        setLoading(false);
                        getCustomers(pageCount);
                        setShowCreatePopup(false);
                    }
                }
            });
        } else if (data?.type === "edit") {
            const formData = data?.formData
            // formData.dob = formData.dob ? formData.dob : "";
            formData.metadata = {};
            delete formData['undefined'];
            setLoading(true);
            USER_UPDATE(selectedData?.uuid, formData).then((res: any) => {
                if (res.status === "fail") {
                    toast.error(res.error, { position: "top-right" });
                    setLoading(false);
                } else {
                    if (res) {
                        setShowCreatePopup(false);
                        toast.success(res.message, { position: 'top-right' })
                        setLoading(false);
                        getCustomers(pageCount);
                    }
                }
            });
        }
        else if (data?.type === "cancel") {
            setShowCreatePopup(false);
        }
    }


    const onDeleteUserConfirmation = () => {
        setLoading(true);
        USER_DELETE({ user_uuid: selectedData?.uuid }).then((res: any) => {
            if (res?.status === "fail") {
                toast.error(res.error, { position: 'top-right', })
                setLoading(false);
            } else {
                if (res) {
                    toast.success(res.message, { position: 'top-right', })
                    setLoading(false);
                    setShowDeletePopup(false);
                    getCustomers(pageCount)
                }
            }
        });
    }
    const onSearch = (value: any) => {
        const pageData = pageCount;
        pageData.search = value;
        setPageCount(pageData);
        getCustomers(pageData);
    }
    const generatePassword = () => {
        // const length = 8;//Math.floor(Math.random() * (16 - 8 + 1)) + 8;
        // const lowercase = 'abcdefghijklmnopqrstuvwxyz';
        // const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        // const numbers = '0123456789';
        // const special = '!@#$%^&*()-_=+[]{}|;:,.<>?';

        // // Ensure at least one character from each required set
        // const getRandomChar = (charset: any) => charset[Math.floor(Math.random() * charset.length)];

        // const passwordChars = [
        //     getRandomChar(lowercase),
        //     getRandomChar(uppercase),
        //     getRandomChar(numbers),
        //     getRandomChar(special)
        // ];

        // // Fill the rest of the password length with random characters from all sets
        // const allChars = lowercase + uppercase + numbers + special;
        // for (let i = passwordChars.length; i < length; i++) {
        //     passwordChars.push(getRandomChar(allChars));
        // }

        // // Shuffle the array to ensure randomness
        // for (let i = passwordChars.length - 1; i > 0; i--) {
        //     const j = Math.floor(Math.random() * (i + 1));
        //     [passwordChars[i], passwordChars[j]] = [passwordChars[j], passwordChars[i]];
        // }

        // return passwordChars.join('');

        const length = Math.floor(Math.random() * 9) + 8; // Generate a length between 8 and 16
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@$!%*?&";
        let newPassword = '';

        while (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/.test(newPassword)) {
            newPassword = Array(length)
                .fill(0)
                .map(() => charset.charAt(Math.floor(Math.random() * charset.length)))
                .join('');
        }
        return newPassword;
    }
    return (
        <div className='container-fluid content-bg-color'>
            <div className='row border-bottom-primary'>
                <div className='col-md-9 text-start d-flex align-items-center px-0'>
                    <h4>Customers</h4>
                </div>
                <div className='col-md-3 text-end my-2 px-0'>
                    <button className='btn btn-primary border-0' onClick={onShowCreateCustomerPopup}>Create Customer</button>
                </div>
            </div>
            <div className='mt-2'>
                {/* <DynamicForm
                    userFormData={searchFormData}
                    columns={4}
                    isEdit={false}
                    onFormSubmitEvent={(e) => onSearchFormSubmit(e)}
                    customClass={"display-block"}
                    isNotEmptyFormAfterSubmit={true} cancelRoute={undefined}
                /> */}
                <div className='w-100 mt-4 pt-1'>
                    <Search onSearch={(e) => onSearch(e)} subscriptionSearch={true} />
                </div>
            </div>
            <Loader LoadingReducer={loading} />
            <div className='row my-3'>
                <div className='col-md-12 px-0'>
                    <DataTable
                        tableData={tableListData}
                        TableCols={CustomersDataGridCols}
                        onActions={(e: any) => onActions(e)}
                        onPageChange={(e: any) => onPageChange(e)}
                        onSelectPageChange={(e) => onSelectPage(e)}
                        pageCount={pageCount}
                    />
                </div>
                {showCreatePopup && (
                    <div className={`modal modal-custom fade ${showCreatePopup ? "show" : "false"}  ${showCreatePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                        <Loader LoadingReducer={loading} />
                        <div className="modal-dialog modal-lg modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header border-bottom-primary mx-4 px-0">
                                    <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">{isEdit ? "Edit" : "Create"} Customer</strong></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowCreatePopup(false)}></button>
                                </div>
                                <div className="modal-body px-4 text-start">
                                    <div className='row'>
                                        <DynamicForm
                                            userFormData={formData}
                                            columns={2}
                                            isEdit={isEdit}
                                            onFormSubmitEvent={(e) => onCustomerFormSubmitEvent(e)}
                                            customClass={"display-block"}
                                            isNotEmptyFormAfterSubmit={true} cancelRoute={undefined} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {showDeletePopup && (
                    <>
                        <div className={`modal modal-custom fade ${showDeletePopup ? "show" : "false"}  ${showDeletePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                            <Loader LoadingReducer={loading} />
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content text-start">
                                    <div className="modal-header border-bottom-primary mx-4 px-0">
                                        <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Delete</strong></h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowDeletePopup(false)}></button>
                                    </div>
                                    <div className="modal-body pb-5">
                                        <div className="row px-2">
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <p className="f16 p-2">Are you sure you want to delete <span className='fw-bold '>{selectedData?.name || ""}</span> ?</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='px-2 text-end'>
                                            <button type="button" className="btn btn-primary px-4 btn-sm f16 me-3" onClick={onDeleteUserConfirmation}>Yes</button>
                                            <button type="button" className="btn btn-outline-primary px-4 btn-sm f16 text-primary-medium border-primary-medium" onClick={() => setShowDeletePopup(false)}>No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
