import { DataTableCol } from "../../../../components/data-table/types";

export const SubscriptionDataGridCols: DataTableCol[] = [


  {
    title: "Payment Partner Name",
    control: "payment_partner_name",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Name",
    control: "plan_name",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Period",
    control: "days",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Price",
    control: "price",
    sortable: true,
    canShowColumn: true,
  },

  {
    title: "Description",
    control: "notes",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Actions",
    control: "actions",
    sortable: false,
    isDelete: true,
    isEdit: true,
    isView: false,
    canShowColumn: true,
  },
];
